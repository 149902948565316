// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes';

// This was set to true by default in the upgrade. We need it to be false.
$nb-enable-css-custom-properties: false;

$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #f7f9fc,
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    slide-out-container-width: 30%,
  ),
  default,
  default
);

$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #252547,
    slide-out-shadow-color: 2px 0 3px #29157a,
    slide-out-shadow-color-rtl: -2px 0 3px #29157a,
    slide-out-container-width: 30%,
  ),
  cosmic,
  cosmic
);

$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    slide-out-container-width: 30%,
  ),
  corporate,
  corporate
);

$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    slide-out-container-width: 30%,
  ),
  dark,
  dark
);

/*
 * Base themes for LockVue platform.
 * These variables are to be shared by all lockvue and white-label themes and
 * can be overridden as necessary.
 */
$nb-themes: nb-register-theme(
  (
    header-height: 4rem,
    header-padding: 1rem,
    logo-height: 2rem,
    // seems to work well with default padding etc
    sidebar-width: 12rem,
    sidebar-header-height: initial,
    sidebar-padding: 1rem,
    menu-item-padding: 0.6rem 1rem,
    layout-padding-top: 1rem,
    layout-padding: 1.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    select-min-width: 6rem,
    footer-logo-url: url('/assets/images/lockvue-logo-color.svg'),
    footer-padding: 0.75rem,
    toggle-height: 1.625rem,
    toggle-width: 2.875rem,
    toggle-switcher-size: 1.5rem,
    toggle-switcher-icon-size: 0.5rem,
  ),
  lockvue_base_light,
  default
);

/*
 * Base themes for LockVue platform.
 * These variables are to be shared by all lockvue and white-label themes and
 * can be overridden as necessary.
 */
$nb-themes: nb-register-theme(
  (
    header-height: 4rem,
    header-padding: 1rem,
    logo-height: 2rem,
    // seems to work well with default padding etc
    sidebar-width: 12rem,
    sidebar-header-height: initial,
    sidebar-padding: 1rem,
    menu-item-padding: 0.6rem 1rem,
    layout-padding-top: 1rem,
    layout-padding: 1.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    select-min-width: 6rem,
    footer-logo-url: url('/assets/images/lockvue-logo-white.svg'),
    footer-padding: 0.75rem,
    toggle-height: 1.625rem,
    toggle-width: 2.875rem,
    toggle-switcher-size: 1.5rem,
    toggle-switcher-icon-size: 0.5rem,
  ),
  lockvue_base_dark,
  dark
);

/*
 * Deployment specific themes for LockVue
 */
$nb-themes: nb-register-theme(
  (
    // theme colors
    color-primary-300: #49beb5,
    color-primary-400: #25ada3,
    color-primary-500: #00a79d,
    color-primary-600: #008077,
    color-primary-700: #00655d,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/lockvue-logo-color.svg'),
    logo-height: 2rem,
    // seems to work well with default padding etc
    logo-width: 12rem,
    email-confirmed-logo-url: url('/assets/images/lockvue-footer-logo.svg'),
    sidebar-header-height: initial
  ),
  lockvue,
  lockvue_base_light
);

/*
 * Deployment specific themes for LockVue
 */
$nb-themes: nb-register-theme(
  (
    // theme colors
    color-primary-300: #49beb5,
    color-primary-400: #25ada3,
    color-primary-500: #00a79d,
    color-primary-600: #008077,
    color-primary-700: #00655d,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/lockvue-logo-white.svg'),
    logo-height: 2rem,
    // seems to work well with default padding etc
    logo-width: 12rem,
    email-confirmed-logo-url: url('/assets/images/lockvue-footer-logo.svg'),
    sidebar-header-height: initial
  ),
  lockvue_dark,
  lockvue_base_dark
);

/**
 * Deployment specific themes for SLS
 */
$nb-themes: nb-register-theme(
  (
    // theme colors
    color-primary-300: #fdb533,
    color-primary-400: #fdac19,
    color-primary-500: #fda300,
    color-primary-600: #e49300,
    color-primary-700: #ca8200,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/sls-logo-color.png'),
    logo-height: 2rem,
    logo-width: 12rem,
    email-confirmed-logo-url: url('/assets/images/lockvue-footer-logo.svg'),
    sidebar-header-height: initial
  ),
  sls,
  lockvue_base_light
);

/**
 * Deployment specific themes for Allied
 */
$nb-themes: nb-register-theme(
  (
    // theme colors
    // color-primary-300: #90e0ef,
    // color-primary-400: #48cae4,
    // color-primary-500: #00b4d8,
    // color-primary-600: #0096c7,
    // color-primary-700: #0077b6,
    // dark primary colors
    // color-primary-300: #2e2f2f,
    // color-primary-400: #282929,
    // color-primary-500: #1e1f1f,
    // color-primary-600: #141515,
    // color-primary-700: #0a0a0a,
    // light primary colors
    // color-primary-300: #ffffff,
    // color-primary-400: #f5f5f5,
    // color-primary-500: #eaebeb,
    // color-primary-600: #e0e1e1,
    // color-primary-700: #d6d7d7,
    // theme colors from RockIT
    color-primary-300: #88a267,
    color-primary-400: #7d985d,
    color-primary-500: #738b55,
    color-primary-600: #687e4e,
    color-primary-700: #5e7246,
    // success alert colors
    color-success-300: #d4edda,
    color-success-400: #c5e7cd,
    color-success-500: #b7e1c1,
    color-success-600: #a9dbb4,
    color-success-700: #9ad5a8,
    toastr-success-text-color: color-basic-800,
    button-filled-success-text-color: color-basic-800,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/rockit_header_logo.png'),
    logo-height: 2rem,
    logo-width: 24rem,
    email-confirmed-logo-url: url('/assets/images/rockit_header_logo.png'),
    sidebar-header-height: initial
  ),
  allied_dark,
  lockvue_base_dark
);

/**
 * Deployment specific themes for Allied
 */
$nb-themes: nb-register-theme(
  (
    // theme colors
    // color-primary-300: #90e0ef,
    // color-primary-400: #48cae4,
    // color-primary-500: #00b4d8,
    // color-primary-600: #0096c7,
    // color-primary-700: #0077b6,
    // dark primary colors
    // color-primary-300: #2e2f2f,
    // color-primary-400: #282929,
    // color-primary-500: #1e1f1f,
    // color-primary-600: #141515,
    // color-primary-700: #0a0a0a,
    // light primary colors
    // color-primary-300: #ffffff,
    // color-primary-400: #f5f5f5,
    // color-primary-500: #eaebeb,
    // color-primary-600: #e0e1e1,
    // color-primary-700: #d6d7d7,
    // theme colors from RockIT
    color-primary-300: #88a267,
    color-primary-400: #7d985d,
    color-primary-500: #738b55,
    color-primary-600: #687e4e,
    color-primary-700: #5e7246,
    // success alert colors
    color-success-300: #d4edda,
    color-success-400: #c5e7cd,
    color-success-500: #b7e1c1,
    color-success-600: #a9dbb4,
    color-success-700: #9ad5a8,
    toastr-success-text-color: color-basic-800,
    button-filled-success-text-color: color-basic-800,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/rockit_header_logo.png'),
    logo-height: 2rem,
    logo-width: 24rem,
    email-confirmed-logo-url: url('/assets/images/rockit_header_logo.png'),
    sidebar-header-height: initial
  ),
  allied_light,
  lockvue_base_light
);

/**
 * Deployment specific themes for Indara
 */
$nb-themes: nb-register-theme(
  (
    // theme colors
    // theme colors from Indara
    color-primary-300: #50aed5,
    color-primary-400: #489dc5,
    color-primary-500: #3a95c4,
    color-primary-600: #319acd,
    color-primary-700: #1785c4,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/indara-logo-color.png'),
    logo-height: 2rem,
    logo-width: 24rem,
    email-confirmed-logo-url: url('/assets/images/lockvue-footer-logo.svg'),
    sidebar-header-height: initial
  ),
  indara,
  lockvue_base_light
);

/**
 * Deployment specific themes for Indara
 */
$nb-themes: nb-register-theme(
  (
    // theme colors
    // theme colors from Indara
    color-primary-300: #50aed5,
    color-primary-400: #489dc5,
    color-primary-500: #3a95c4,
    color-primary-600: #319acd,
    color-primary-700: #1785c4,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/indara_logo_dark.png'),
    logo-height: 2rem,
    logo-width: 24rem,
    email-confirmed-logo-url: url('/assets/images/lockvue-footer-logo.svg'),
    sidebar-header-height: initial
  ),
  indara_dark,
  lockvue_base_dark
);

$nb-themes: nb-register-theme(
  (
    // theme colors
    // theme colors from Indara
    color-primary-300: #fb776e,
    color-primary-400: #fb776e,
    color-primary-500: #f34b40,
    color-primary-600: #e02e22,
    color-primary-700: #bc2319,
    // fonts, text
    font-family-primary: unquote('Montserrat, Roboto, "Helvetica Neue", sans-serif'),
    text-paragraph-font-size: 0.86rem,
    text-paragraph-line-height: 1rem,
    text-heading-2-font-size: 1.5rem,
    text-heading-2-line-height: 1.5rem,
    text-heading-3-font-size: 1.35rem,
    text-heading-3-line-height: 1.35rem,
    text-heading-5-font-size: 1.2rem,
    text-heading-5-line-height: 1.2rem,
    // Sidebar, header etc
    logo-url: url('/assets/images/timberlands_full.svg'),
    logo-height: 3rem,
    logo-width: 9rem,
    email-confirmed-logo-url: url('/assets/images/timberlands_full.svg'),
    sidebar-header-height: initial
  ),
  timberlands,
  lockvue_base_light
);
