@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ddh6jt');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ddh6jt#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ddh6jt') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ddh6jt') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ddh6jt##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.keyrack {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.keyrack-router {
  &::before {
    content: $keyrack-router;
  }
}
.keyrack-keyrack-full {
  &::before {
    content: $keyrack-keyrack-full;
  }
}
.keyrack-First-K {
  &::before {
    content: $keyrack-First-K;
  }
}
.keyrack-First-NK {
  &::before {
    content: $keyrack-First-NK;
  }
}
.keyrack-Last-K {
  &::before {
    content: $keyrack-Last-K;
  }
}
.keyrack-Last-NK {
  &::before {
    content: $keyrack-Last-NK;
  }
}
.keyrack-Mid-K {
  &::before {
    content: $keyrack-Mid-K;
  }
}
.keyrack-Mid-NK {
  &::before {
    content: $keyrack-Mid-NK;
  }
}
