$icomoon-font-family: 'keyrack' !default;
$icomoon-font-path: 'fonts' !default;

$keyrack-router: '\e907';
$keyrack-keyrack-full: '\e906';
$keyrack-First-K: '\e900';
$keyrack-First-NK: '\e901';
$keyrack-Last-K: '\e902';
$keyrack-Last-NK: '\e903';
$keyrack-Mid-K: '\e904';
$keyrack-Mid-NK: '\e905';
